export const ports = [
    {id:55,port:'大连'},
    {id:383,port:'营口'},
    {id:252,port:'秦皇岛'},
    {id:161,port:'京唐'},
    {id:28,port:'曹妃甸'},
    {id:307,port:'天津'},
    {id:137,port:'黄骅'},
    {id:376,port:'烟台'},
    {id:248,port:'青岛'},
    {id:258,port:'日照'},
    {id:180,port:'连云港'},
    {id:273,port:'上海'},
    {id:233,port:'宁波'},
    {id:413,port:'舟山'},
    {id:101,port:'福州'},
    {id:336,port:'厦门'},
    {id:275,port:'汕头'},
    {id:279,port:'深圳'},
    {id:2017,port:'香港'},
    {id:110,port:'广州'},
    {id:403,port:'湛江'},
    {id:18,port:'北海'},
    {id:253,port:'钦州'},
    {id:78,port:'防城港'},
    {id:116,port:'海口'},
    {id:371,port:'洋浦'},
    {id:1207,port:'基隆'},
    {id:1214,port:'台北'},
    {id:1213,port:'台中'},
    {id:1206,port:'高雄'},
]
                                            
               