<template>
    <div class="wrapper" info="轨迹查询">
        <!--
            <a-modal title="船舶查询" :visible="isSearch" :confirm-loading="confirmLoading" @ok="handleOk" @cancel="handleCancel" :footer="false" centered width="450px">
            <a-form :form="form">
                <a-form-item :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol" label="">
                    <a-input v-decorator="[
          'shipNo',
          { rules: [{ required: true, message: 'Please input your name' }] },
        ]" placeholder="输入船号/AIS设备码/IMO查询" />
                </a-form-item>

                <a-form-item :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol">
                    <a-button type="primary" @click="check" style="width:100%">
                        查询
                    </a-button>
                </a-form-item>
            </a-form>
        </a-modal>

        -->

        <a-modal :title="shipTitle||'结果-船舶查询'" :visible="isResult" :confirm-loading="confirmLoading" @ok="handleOk" @cancel="handleCancelResult" :footer="false" width="600px" :mask="false" >
            <a-tabs default-active-key="1" @change="callback" style="height:300px;background:#f1f1f1">
                <a-tab-pane :key="item.id" :tab="item.title" v-for="(item,index) in tabs">

                    <template v-if="index==0">
                        <div class="tabContent">
                              <div class="loading" v-if="isResultLoading" style="text-align:center;line-height:180px">
                                <a-icon type="loading-3-quarters" spin/>
                            </div>
                            <div class="contentRow" v-else>
                                <div>英文船名：{{item.content.shipnameEn||'暂无'}}</div>
                                <div>MMSI： {{item.content.mmsi||'暂无'}}</div>
                                <div>IMO: {{item.content.imo||'暂无'}}</div>
                                <div>呼号: {{item.content.callsign||'暂无'}}</div>

                                <div>预到时间: {{item.content.eta||'暂无'}}</div>
                                <div>长*宽(米):  {{item.content.length}} *{{item.content.breadth}}（米）</div>
                                <div>吃水： {{item.content.draught||'暂无'}} </div>
                                <div>目的地: {{item.content.destPort||'暂无'}}</div>

                            </div>
                        </div>
                    </template>
                    <template v-if="index==1">
                        <div class="tabContent">
                           
                            <div class="loading" v-if="isResultLoading" style="text-align:center;line-height:180px">
                                <a-icon type="loading-3-quarters" spin/>
                            </div>
                            <div class="contentRow" v-else>
                                <div>更新时间: {{getFormatDay(item.content[0].posTime*1000,true)||'暂无'}}</div>
                                <div>船艏向: {{item.content[0].heading||'暂无'}}</div>
                                <div>航向: {{item.content[0].cog?item.content[0].cog/10+'度':'暂无'}}</div>
                                <div>航速: {{item.content[0].sog?item.content[0].sog*1.852.toFixed(4)+"km/h":'暂无' }}</div>
                                <div>经度: {{item.content[0].lat/1000000}}</div>
                                <div>纬度: {{item.content[0].lon/1000000}}</div>
                            </div>
                        </div>
                    </template>
                </a-tab-pane>
            </a-tabs>
            <div class="btnline" style="margin-top:18px">
                 <a-button @click="btnClick(1)" :type="isAttation?'primary':''" style="float:left">{{isAttation?'已关注':"关注船舶"}}</a-button>
                <a-button @click="btnClick(2)">历史轨迹</a-button>
                <a-button @click="btnClick(3)">历史航次</a-button>
            </div>
        </a-modal>

        <a-modal :title="shipTitle" :visible="isHistoryLine" :confirm-loading="confirmLoading" @ok="handleOk" @cancel="handleCancel" :footer="false" centered width="600px" :mask="false">
            <div class="btnline">
                <a-button @click="getDate(-3)" :type="currentDay==3?'primary':''">近3天</a-button>
                <a-button @click="getDate(-7)" :type="currentDay==7?'primary':''">近7天</a-button>
                <a-button @click="getDate(-15)" :type="currentDay==15?'primary':''">近15天</a-button>
                <a-button @click="getDate(-30)" :type="currentDay==30?'primary':''">近1个月</a-button>
            </div>
            <div class="btnline" style="margin-top:18px">
               
                 <a-range-picker :disabled-date="disabledDate" format="YYYY/MM/DD " @change="onChange" :locale="locale" v-model="dates"  />
                <a-button @click="searchHistoryLine" style="width:135px;margin-left:8px">查询</a-button>
            </div>

            <div class="lineWrapper" style="margin-top:18px;background:#eee;padding:0 5px;max-height:400px;overflow:hidden;overflow-y:auto;min-height:200px">
                <div class="loading" v-if="histroyLineLoading" style="text-align:center;line-height:180px">
                    <a-icon type="loading-3-quarters" spin />
                </div>
                <template v-else-if="histroyLine.length>0">
                    <div class="btnline" v-for="item in histroyLine " :key="item.posTime" style="align-items: center;border-bottom:1px solid #fff; height:50px ">
                      
                        <div>{{getFormatDay(item.posTime*1000,true)}} </div>
                        <div>经度{{item.lat/1000000}} </div>
                        <div>纬度{{item.lon/1000000}} </div>
                        <!-- <div>  <a-button>导出</a-button> </div>  -->
                    </div>
                </template>
                <template v-else-if="histroyLine.length==0">
                    <div style="border-bottom:1px solid #f1f1f1; font-size:20px;width:100% ;text-align:center;line-height:200px">
                        暂无数据
                    </div>
                </template>

            </div>
        </a-modal>

        <a-modal :title="shipTitle+' 历史航次'" :visible="isHistoryShip" :confirm-loading="confirmLoading" @ok="handleOk" @cancel="handleCancel" :footer="false" centered width="600px" :mask="false">

            <div class="lineWrapper" style="margin-top:18px;background:#eee;padding:0 5px;max-height:400px;overflow:hidden;overflow-y:auto;min-height:200px;padding:10px;padding-top:0">
                <div class="loading" v-if="histroyShipsLoading" style="text-align:center;line-height:180px">
                    <a-icon type="loading-3-quarters" spin />
                </div>
                <template v-else-if="  histroyShips.length>0">
                    <div class="btnline" v-for="item in histroyShips " :key="item.posTime" style="align-items: center;border-bottom:1px solid #f1f1f1; height:50px ">
                        <!--<div>{{shipTitle}} </div>-->
                        <div>{{item.countryChs}}{{item.portNameChs}} </div>
                        <div>入港: {{getFormatDay(item.inTime*1000,true)}} </div>
                        <div>出港: {{getFormatDay(item.outTime*1000,true)}} </div>

                        <!-- <div>  <a-button>导出</a-button> </div>  -->
                    </div>
                </template>
                <template v-else-if="histroyShips.length==0">
                    <div style="border-bottom:1px solid #f1f1f1; font-size:20px;width:100% ;text-align:center;line-height:200px">
                        暂无数据
                    </div>
                </template>

            </div>
        </a-modal>
    </div>
</template>

<script>
import moment from "moment";
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";
import {
    shipDetail,
    shipAsc,
    histroyLine,
    histroyShip,
    attention,
} from "@/request/api.js";
const formItemLayout = {
    labelCol: { span: 0 },
    wrapperCol: { span: 24 },
};

export default {
    props: {
        shipId: String,
        shipName:String
    },
    data() {
        return {
            formItemLayout,
            form: this.$form.createForm(this, { name: "dynamic_rule" }),
            isSearch: false,
            isResult: true,
            isHistoryLine: false, //历史轨迹
            isHistoryShip: false, //历史航次
            confirmLoading: false,
            isResultLoading: false,
            tabs: [
                { title: "基本信息", id: 1, content: "thisis content1" },
                { title: "AIS信息", id: 2, content: "thisis content2" },
            ],
            shipTitle: "",
            locale,
            startDate: "",
            endDate: "",
            histroyLine: [], //历史轨迹
            histroyLineLoading: false,
            currentDay: 0, //选择日期的按扭
            lastYearDay: "", //去年的今日
            histroyShips: [], //历史航次
            histroyShipsLoading: false,
            dates: [],
            currentItem: {},
            isAttation: false,
            showMyAttation:false,
            dateFormat: 'YYYY/MM/DD',
            
        };
    },
    created() {},
    mounted() {
        this.shipDetail();
         this.shipAsc("first",this.shipName);
        this.lastYearDay = this.getFormatDay(
            new Date().getTime() - 365 * 24 * 60 * 60 * 1000
        );
    },
    watch: {},
    computed: {},
    methods: {
        check() {
            (this.isSearch = false), (this.isResult = true);
        },
        handleOk() {
            this.isSearch = false;
        },
        handleCancel() {
            this.isHistoryLine = this.isHistoryShip = false;
        },
        handleCancelResult() {
            this.$emit("close");
        },
        btnClick(flag) {
            //this.isResult = false;
            if (flag == 2) {
                this.isHistoryLine = true;
            }
            if (flag == 3) {
                this.isHistoryShip = true;
                this.searchHistroyShip();
            }
            if (flag == 1) {
                console.log("attion>>>");
                this.isAttation = true;
                attention({
                    shipId: this.shipId,
                    mmsi: this.currentItem.mmsi,
                    name: this.currentItem.shipnameEn,
                }).then((res) => {});
            }
        },

        moment,
        onChange(dates, dateStrings) {
             console.log("aaathis.dates>>",this.dates)
            console.log(dateStrings);
            let startDate = dateStrings[0].slice(0, 10);
            let endDate = dateStrings[1].slice(0, 10);
            this.startDate = startDate;
            this.endDate = endDate;
            this.currentDay = 0;
        },
        callback(p1, p2) {
            console.log("p2>>>", p1, p2);
            if (p1 == 1) {
                this.shipDetail();
            }
            if (p1 == 2) {
                this.shipAsc();
            }
        },
        shipDetail() {
            console.log("shipDetail");
            this.isResultLoading = true;
            shipDetail({ shipId: this.shipId }).then((res) => {
                console.log("detail::", res);
                this.tabs[0].content = JSON.parse(res.data);
                 this.isAttation = JSON.parse(res.data).attention;
                this.currentItem = JSON.parse(res.data);
                this.isResultLoading = false;
                this.shipTitle = "MMSI:" + JSON.parse(res.data).mmsi;
            });
        },
       
        shipAsc(flag,name) {
            this.isResultLoading = true;
            shipAsc({ shipId: this.shipId }).then((res) => {
                this.isResultLoading = false;
                console.log("res0",res)
                this.tabs[1].content = JSON.parse(res.data);
                if (flag && flag == "first") {
                    this.$parent.setCenter(this.tabs[1].content[0], name);
                }
            });
        },
        searchHistoryLine() {
            let shipId = this.shipId;
            //;
            console.log("aa", this.startDate, this.endDate);
            if (!this.startDate || !this.endDate) {
                this.$warning({
                    title: "提示",
                    content: "请选择日期",
                    okText: "确定",
                });
                return false;
            }
            /*
            let startDate = this.startDate.replaceAll("/", "");
            let endDate = this.endDate.replaceAll("/", "");
            */
            
            let startDate = this.startDate.slice(0,4)+this.startDate.slice(5,7)+this.startDate.slice(8)
            let endDate = this.endDate.slice(0,4)+this.endDate.slice(5,7)+this.endDate.slice(8)
            console.log(startDate,endDate)
            let data = { shipId, startDate, endDate };
            if (Number(endDate) - Number(startDate) > 100) {
                this.$warning({
                    title: "提示",
                    content: "日期跨度不能超过1个月",
                    okText: "确定",
                });
                return false;
            }
            console.log(data);
            this.histroyLine = [];
            this.histroyLineLoading = true;
            histroyLine(data).then((res) => {
                this.histroyLine = JSON.parse(res.data);
                console.log(this.histroyLine);
                this.histroyLineLoading = false;
                if (this.histroyLine.length > 0) {
                    this.$parent.createLine(this.histroyLine);
                }
            });
        },
        searchHistroyShip() {
            this.histroyShipsLoading = true;
            histroyShip({ shipId: this.shipId }).then((res) => {
                this.histroyShips = JSON.parse(res.data).portHisItems;
                this.histroyShipsLoading = false;
                console.log(this.histroyShips);
            });
        },
        getDate(dayNum) {
              let currentDate = new Date().getTime();
            console.log(currentDate);
            let startDate = new Date(
                currentDate + dayNum * 24 * 60 * 60 * 1000
            ).getTime();
            let startDateFormat = this.getFormatDay(startDate);
            let endDateFormat = this.getFormatDay(currentDate);

            if (dayNum > 0) {
                startDateFormat = this.getFormatDay(currentDate);
                endDateFormat = this.getFormatDay(startDate);
            }
            console.log(startDateFormat, endDateFormat);
            this.startDate = startDateFormat;
            this.endDate = endDateFormat;
            this.dates =[ moment(this.startDate,this.dateFormat), moment(this.endDate,this.dateFormat)]
            console.log(this.startDate,this.endDate);
            console.log(this.dates)
            this.currentDay = Math.abs(dayNum);
        },
        //flag为true要取到时分秒
        getFormatDay(millNum, flag, line = "/") {
            let date = new Date(millNum);
            let year = date.getFullYear();
            let month =
                date.getMonth() + 1 < 10
                    ? "0" + (date.getMonth() + 1)
                    : date.getMonth() + 1;
            let day =
                date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
            //  return year +"/"+ month +"/"+day;
            if (flag) {
                let hours = date.getHours();
                if (hours < 10) {
                    hours = "0" + hours;
                }
                let minutes = date.getMinutes(); //获取当前分钟
                if (minutes < 10) {
                    minutes = "0" + minutes;
                }
                let seconds = date.getSeconds(); //获取当前秒
                if (seconds < 10) {
                    seconds = "0" + seconds;
                }
                return (
                    year +
                    line +
                    month +
                    line +
                    day +
                    " " +
                    hours +
                    ":" +
                    minutes +
                    ":" +
                    seconds
                );
            }
            return year + line + month + line + day;
        },
        disabledDate(current) {
            // Can not select days before today and today
            let _lastYearDay = this.lastYearDay.replace("/", "-");
            return (
                (current && current > moment().endOf("day")) ||
                current < moment(_lastYearDay).endOf("day")
            );
        },
    },
    components: {},
};
</script>
<style scoped>
.btnline {
    display: flex;
    justify-content: space-between;
}
.btnline a-button {
    width: 20%;
}

.contentRow {
    display: flex;
    flex-wrap: wrap;
    padding-left: 18px;
    line-height: 30px;
}
.contentRow > div {
    width: 50%;
}
</style>