<template>
    <div id="app">
        <div id="nav" class="nav">

            <a-input ref="userNameInput" v-model.trim="searchStr" placeholder="输入船号/AIS设备码/IMO查询" class="search" allowClear @focus="focus" size="large">
                <a-icon slot="prefix" type="search" />
            </a-input>
        </div>

        <div class="shiplist" v-if="shipList.length>0 && shipListShow">
            <div class="shipItem" v-for="(item) in shipList" :key="item.shipId" @click="clickShipItem(item.shipId,item.name)" v-bind="item">
                <span style="padding-left:8px"><b>{{item.name}}</b></span>
                <span style="margin-left:20px" v-if="item.mmsi"><b>MMSI：</b>{{item.mmsi}}</span>
                <span style="margin-left:20px" v-else-if="item.imo"><b>imo</b>{{item.imo}}</span>
            </div>
        </div>

        <Login v-if="showLogin" @close="showLogin = false"></Login>
        <Travel v-if="showTravel" @close="showTravel = false" :shipId="currentShipId" :shipName="currentShipName" style="position:fixed;width:100%;height:100%;border:20px solid blue;"></Travel>
        <MyAttation v-if="showMyAttation" @close="showMyAttation= false"></MyAttation>
        <Port v-if="showPort" @close="showPort= false"></Port>
        <div id="container" class="container" style="width:100%;height:100%;"></div>

        <div class="footer">
            <div class="navItem" v-for="item in tabImgs" :key="item.img" @click="clickNav(item.navId)">
                <img :src="currentNav==item.navId?item.imgCurrent:item.img" alt="">
                <p :style="currentNav==item.navId?'color:#EC8E37':''">{{item.title}}</p>
            </div>
        </div>
        <div class="subNav" info="地图切换" v-if="showSubNav">
            <div :class="currentSubNav==1?'current':''" @click="clickSubNav(1)">海图</div>
            <div :class="currentSubNav==2?'current':''" @click="clickSubNav(2)">地图</div>
            <div :class="currentSubNav==3?'current':''" @click="clickSubNav(3)">卫星图</div>
        </div>

    </div>
</template>
<script>
import _ from "lodash";
import AMapLoader from "@amap/amap-jsapi-loader";
import { shipList, login } from "@/request/api.js";
import Login from "@/components/Login.vue";
import Travel from "@/components/Travel.vue";
import MyAttation from "@/components/MyAttation.vue";
import Port from "@/components/Port.vue";
export default {
    data() {
        return {
            menuShow: false,
            map: null,
            showLogin: false,
            showTravel: false,
            showMyAttation: false,
            showPort: false,
            showSubNav: false, //地图切换
            searchStr: "",
            shipList: [],
            shipListShow: true,
            currentShipId: null,
            currentShipName: null,
            aMap: null,
            currentNav: 0, //底部导航分别为1，2，3
            tabImgs: [
                {
                    img: require("../assets/track/tab1.png"),
                    imgCurrent: require("../assets/track/tab1_current.png"),
                    title: "港口查询",
                    navId: 1,
                },
                {
                    img: require("../assets/track/tab2.png"),
                    imgCurrent: require("../assets/track/tab2_current.png"),
                    title: "地图切换",
                    navId: 2,
                },
                {
                    img: require("../assets/track/tab3.png"),
                    imgCurrent: require("../assets/track/tab3_current.png"),
                    title: "关注船舶",
                    navId: 3,
                },
            ],
            tel: "",
            satellite: null,
            seaMap: null,
            currentSubNav: 2,
        };
    },
    created() {
        console.log("==", this.$route);
        this.debounceGetShipList = _.debounce(this.getShipList, 500);
        console.log(this.$route);
        let from = this.$route.query.from ? this.$route.query.from : "0";
        if (from && from == "wx") {
            let token = this.$route.query.from.token;
            console.log("token>>", token);
            localStorage.setItem("token");
        }
    },
    mounted() {
        console.log("==", this.$route);
        this.initMap(this.createLayer2);
        this.checkFrom();
    },
    watch: {
        "$route.path": {
            immediate: true,
            handler() {},
        },
        searchStr: function (newV, oldV) {
            // this.debounceGetShipList();
            console.log(newV, oldV);
            this.getShipList();
        },
    },

    methods: {
        checkFrom() {
            if (this.$route.query && this.$route.query.phone) {
                console.log("ff", this.$route.query.phone);
                login({ phone: this.$route.query.phone, code: "9999" }).then(
                    (res) => {
                        localStorage.setItem("token", res.data);
                        localStorage.setItem("tel", this.$route.query.phone);
                        this.setTel(this.$route.query.phone);
                    }
                );
            }
        },
        clickNav(navId) {
            //if(navId == this.currentNav){return false}

            if (navId == 1 || navId == 3) {
                if (!localStorage.getItem("tel")) {
                    this.showLogin = true;
                    return false;
                }
            }

            this.showPort = false;
            this.showMyAttation = false;

            if (navId == 1) {
                if (this.currentNav == 2 && this.showSubNav == true) {
                    this.showSubNav = false;
                    setTimeout(() => {
                        this.showPort = true;

                        this.currentNav = navId;
                    }, 500);
                    return false;
                }
                this.showPort = true;
                this.showSubNav = false;
                this.currentNav = navId;
            }
            if (navId == 2) {
                setTimeout(() => {
                    this.currentNav = 2;
                    this.showSubNav = true;
                }, 500);
            }
            if (navId == 3) {
                if (this.currentNav == 2 && this.showSubNav == true) {
                    this.showSubNav = false;
                    setTimeout(() => {
                        this.showMyAttation = true;

                        this.currentNav = navId;
                    }, 500);
                    return false;
                }
                if (this.currentNav == 2) {
                    setTimeout(() => {
                        this.showMyAttation = true;
                        this.showSubNav = false;
                        this.currentNav = navId;
                    }, 500);

                    return false;
                }
                this.showMyAttation = true;
                this.showSubNav = false;
                this.currentNav = navId;
            }
        },
        clickSubNav(subNavId) {
            if (subNavId == this.currentSubNav) {
                return false;
            }
            this.currentSubNav = subNavId;

            if (this.seaMap) {
                this.map.setMapStyle("amap://styles/normal");
            }
            if (this.satellite) {
                this.map.removeLayer(this.satellite);
            }
            if (this.currentSubNav == 1) {
                this.seaMap = true;
                this.map.setMapStyle("amap://styles/macaron"); //normal
            }
            if (this.currentSubNav == 3) {
                this.satellite = new this.aMap.TileLayer.Satellite();
                this.map.addLayer(this.satellite);
            }
            setTimeout(() => {
                this.showSubNav = false;
            }, 500);
        },
        mapHandle(a, b, c) {
            console.log(a, b, c);
        },
        focus() {
            console.log("focus");
            this.shipListShow = true;
            this.showSubNav = false
        },

        getShipList() {
            shipList({ searchStr: this.searchStr }).then((res) => {
                this.shipList = JSON.parse(res.data);
                console.log(this.shipList);
            });
        },
        clickShipItem(shipId, name) {
            if (!localStorage.getItem("tel")) {
                this.showLogin = true;
                return false;
            }
            this.shipListShow = false;
            this.showTravel = true;
            this.currentShipId = shipId;
            this.currentShipName = name;
        },
        initMap(fn) {
            AMapLoader.load({
                key: "1596858bb1c31d534b1ae15242a01389", // 申请好的Web端开发者Key，首次调用 load 时必填
                version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
                plugins: [""], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
            })
                .then((AMap) => {
                    this.aMap = AMap;
                    this.map = new AMap.Map("container", {
                        //设置地图容器id
                        viewMode: "3D", //是否为3D地图模式
                        zoom: 3, //初始化地图级别
                        center: [116.2, 39.56], //初始化地图中心点位置
                        rotateEnable:false,
                    });
                    if (fn) {
                        fn();
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        },
        createLayer2() {
            // 创建 WMS 标准图层
            var wms = new this.aMap.TileLayer.WMS({
                url: "http://124.70.182.151/geoserver/track/wms?service=WMS&version=1.1.0&request=GetMap&layers=track%3Atestlat-cn&bbox=72.11604%2C0.831148%2C137.951667%2C53.49346&width=768&height=614&srs=EPSG%3A4326&format=text%2Fhtml%3B%20subtype%3Dopenlayers", // wms服务的url地址
                // url: "https://g.myships.com/dist/wms?SERVICE=WMS&VERSION=1.1.0&REQUEST=GetMap&FORMAT=image%2Fpng&TRANSPARENT=true&tiled=true&LAYERS=myships%3Apos_latest_3857_1hour&exceptions=application%2Fvnd.ogc.se_inimage&tilesOrigin=-20037508.342789244%2C-20048966.1040146&WIDTH=256&HEIGHT=256&SRS=EPSG%3A3857&STYLES=&BBOX=10018754.17139462%2C0%2C15028131.257091932%2C5009377.085697311", // wms服务的url地址
                blend: false, // 地图级别切换时，不同级别的图片是否进行混合
                tileSize: 512, // 加载WMS图层服务时，图片的分片大小
                params: {
                    LAYERS: "topp:states",
                    VERSION: "1.1.0",
                }, // OGC标准的WMS地图服务的GetMap接口的参数
            });

            this.map.add(wms);
        },
        setTel(tel) {
            console.log(tel);
            this.tel = tel;
        },
        setCenter(obj, shipName) {
            if (this.polyline && this.markers) {
                this.map.remove(this.polyline);
                this.map.remove(this.markers);
            }
            if (this.marker) {
                this.map.remove(this.marker);
            }
            console.log(obj);
            let center = this.wgs84togcj02(obj.lon / 600000, obj.lat / 600000);

            let icon = new this.aMap.Icon({
                size: new this.aMap.Size(14, 19), // 图标尺寸
                image: "https://www.hao3you.com/img/sign.png", // Icon的图像
                imageOffset: new this.aMap.Pixel(0, 0), // 图像相对展示区域的偏移量，适于雪碧图等
                imageSize: new this.aMap.Size(14, 19), // 根据所设置的大小拉伸或压缩图片
            });

            this.marker = new this.aMap.Marker({
                position: center,
                icon: icon,
                anchor: "bottom-center",
                offset: new this.aMap.Pixel(-10, -10),
                angle: obj.cog / 10,
            });

            this.marker.setMap(this.map);

            // 设置鼠标划过点标记显示的文字提示
            this.marker.setTitle("我是marker的title");

            // 设置label标签
            // label默认蓝框白底左上角显示，样式className为：amap-marker-label
            this.marker.setLabel({
                direction: "right",
                offset: new this.aMap.Pixel(10, 0), //设置文本标注偏移量
                content: "<div class='info' >" + shipName + "</div>", //设置文本标注内容
            });

            this.map.setCenter(center, false, 10);
        },
        wgs84togcj02(lng, lat) {
            var x_PI = (3.14159265358979324 * 3000.0) / 180.0;
            var PI = 3.1415926535897932384626;
            var a = 6378245.0;
            var ee = 0.00669342162296594323;
            if (
                lng < 72.004 ||
                lng > 137.8347 ||
                lat < 0.8293 ||
                lat > 55.8271 ||
                false
            ) {
                return [lng, lat];
            } else {
                var dlat = ((lng, lat) => {
                    var ret =
                        -100.0 +
                        2.0 * lng +
                        3.0 * lat +
                        0.2 * lat * lat +
                        0.1 * lng * lat +
                        0.2 * Math.sqrt(Math.abs(lng));
                    ret +=
                        ((20.0 * Math.sin(6.0 * lng * PI) +
                            20.0 * Math.sin(2.0 * lng * PI)) *
                            2.0) /
                        3.0;
                    ret +=
                        ((20.0 * Math.sin(lat * PI) +
                            40.0 * Math.sin((lat / 3.0) * PI)) *
                            2.0) /
                        3.0;
                    ret +=
                        ((160.0 * Math.sin((lat / 12.0) * PI) +
                            320 * Math.sin((lat * PI) / 30.0)) *
                            2.0) /
                        3.0;
                    return ret;
                })(lng - 105.0, lat - 35.0);
                var dlng = ((lng, lat) => {
                    var ret =
                        300.0 +
                        lng +
                        2.0 * lat +
                        0.1 * lng * lng +
                        0.1 * lng * lat +
                        0.1 * Math.sqrt(Math.abs(lng));
                    ret +=
                        ((20.0 * Math.sin(6.0 * lng * PI) +
                            20.0 * Math.sin(2.0 * lng * PI)) *
                            2.0) /
                        3.0;
                    ret +=
                        ((20.0 * Math.sin(lng * PI) +
                            40.0 * Math.sin((lng / 3.0) * PI)) *
                            2.0) /
                        3.0;
                    ret +=
                        ((150.0 * Math.sin((lng / 12.0) * PI) +
                            300.0 * Math.sin((lng / 30.0) * PI)) *
                            2.0) /
                        3.0;
                    return ret;
                })(lng - 105.0, lat - 35.0);
                var radlat = (lat / 180.0) * PI;
                var magic = Math.sin(radlat);
                magic = 1 - ee * magic * magic;
                var sqrtmagic = Math.sqrt(magic);
                dlat =
                    (dlat * 180.0) /
                    (((a * (1 - ee)) / (magic * sqrtmagic)) * PI);
                dlng =
                    (dlng * 180.0) / ((a / sqrtmagic) * Math.cos(radlat) * PI);
                var mglat = lat + dlat;
                var mglng = lng + dlng;
                return [mglng, mglat];
            }
        },
        createLine(listParam) {
           
             console.log("old>>", listParam);
            let newList = this.renewList(listParam);
            console.log("newList", newList);
           let list = newList.map((item, index) => {
                let newLoc = this.wgs84togcj02(
                    item.lon / 600000,
                    item.lat / 600000
                );
                if (index == 0) {
                    console.log(newLoc);
                }
                let newItem = { ...item, lon: newLoc[0], lat: newLoc[1] };
                return newItem;
            });
            
            if (this.polyline && this.markers) {
                this.map.remove(this.polyline);
                this.map.remove(this.markers);
            }
            
            let path = list.map(
                (item) => new this.aMap.LngLat(item.lon, item.lat)
            );
            console.log("path", path);
            // 创建折线实例
            this.polyline = new this.aMap.Polyline({
                path: path,
                borderWeight: 10, // 线条宽度，默认为 1
                strokeColor: "red", // 线条颜色
                lineJoin: "round", // 折线拐点连接处样式
            });

            let icon = new this.aMap.Icon({
                size: new this.aMap.Size(10, 10), // 图标尺寸
                image: "https://www.hao3you.com/img/sign.png", // Icon的图像
                imageOffset: new this.aMap.Pixel(0, 0), // 图像相对展示区域的偏移量，适于雪碧图等
                imageSize: new this.aMap.Size(10, 10), // 根据所设置的大小拉伸或压缩图片
            });

            this.markers = list.map(
                (item) =>
                    new this.aMap.Marker({
                        position: new this.aMap.LngLat(item.lon, item.lat),
                        offset: new this.aMap.Pixel(-5, -10),
                        title:
                            this.getFormatDay(item.posTime * 1000, true) +
                            "  航向:" +
                            item.cog / 10 +
                            "  航速:" +
                            item.sog / 10 +
                            "节",
                        icon: icon,
                        angle: item.cog / 10,
                        zoom: 9,
                    })
            );

            //将折线添加至地图实例
            this.map.add(this.polyline);
            this.map.add(this.markers);
            var position = [list[0].lon, list[0].lat];
            this.map.setCenter(position, false, 10);
            this.map.setZoom(5, false, 10);
            let labelsLayer = new this.aMap.LabelsLayer({
                zooms: [3, 20],
                zIndex: 1000,
                // 该层内标注是否避让
                collision: true,
                // 设置 allowCollision：true，可以让标注避让用户的标注
                allowCollision: true,
            });
            list.forEach((item, index) => {
                let position = [item.lon, item.lat];
                let text = {
                    lnglat: position, //点标记位置
                    content: this.getFormatDay(item.posTime * 1000, true),
                    id: 1,
                };
                let labelMarker = new this.aMap.LabelMarker({
                    name: "标注2", // 此属性非绘制文字内容，仅最为标识使用
                    position: position,
                    zIndex: 16,
                    // 将第一步创建的 icon 对象传给 icon 属性
                    // icon: icon,
                    // 将第二步创建的 text 对象传给 text 属性
                    text: text,
                });
              
                
                labelsLayer.add(labelMarker);
            });
            this.map.add(labelsLayer)

            //this.map.setFitView([this.polyline, this.markers]);
        },
        renewList(list) {
            if (list.length == 0) {
                return list;
            }
           if (list.length == 0 || list.length<30) {
                return list;
            }
            let flag_num =1;
            if(list.length >29 && list.length < 100){
                flag_num =2
            }else{
                flag_num = Math.floor(list.length / 100); //逢flag_num留下
                console.log("flag_num", flag_num);

            }
            let first = list[0];
            let last = list[list.length - 1];
            let _list = list.slice(1, list.length - 1);
            let _list2 = _list.filter((item, index) => {
                if ((index + 1) % flag_num == 0) {
                    return true;
                } else {
                    return false;
                }
            });
            return [first, ..._list2, last];
        },
        //flag为true要取到时分秒
        getFormatDay(millNum, flag, line = "/") {
            let date = new Date(millNum);
            let year = date.getFullYear();
            let month =
                date.getMonth() + 1 < 10
                    ? "0" + (date.getMonth() + 1)
                    : date.getMonth() + 1;
            let day =
                date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
            //  return year +"/"+ month +"/"+day;
            if (flag) {
                let hours = date.getHours();
                if (hours < 10) {
                    hours = "0" + hours;
                }
                let minutes = date.getMinutes(); //获取当前分钟
                if (minutes < 10) {
                    minutes = "0" + minutes;
                }
                let seconds = date.getSeconds(); //获取当前秒
                if (seconds < 10) {
                    seconds = "0" + seconds;
                }
                return (
                    year +
                    line +
                    month +
                    line +
                    day +
                    " " +
                    hours +
                    ":" +
                    minutes +
                    ":" +
                    seconds
                );
            }
            return year + line + month + line + day;
        },
    },
    components: {
        Travel,
        Login,
        MyAttation,
        Port,
    },
};
</script>
<style lang="scss">
#appbak {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}
#app {
    width: 100%;
    height: 100%;
}
.nav {
    width: 100%;
    height: 50px;
    background: rgba(0, 0, 0, 0);
    position: fixed;
    z-index: 10;
    border: 0px solid red;
}
.container {
    position: fixed;
    top: 0px;
    left: 0;
    position: fixed;
    right: 0;
    bottom: 70px;
    margin: auto;
}
.nav .logo {
    width: 35px;
    position: absolute;
    left: 10px;
    top: 9px;
    border: 0px solid red;
}
.nav .top_right {
    width: 25px;
    position: absolute;
    right: 10px;
    top: 13px;
    display: block;
}
.ant-dropdown-menu.ant-dropdown-menu.ant-dropdown-content {
    background: #070c37 !important;
    color: #fff !important;
    width: 140px;
    text-align: center;
    letter-spacing: 1px;
}
.ant-dropdown-menu-item > a {
    color: #ddd !important;
}
.ant-dropdown-menu-item > a > .router-active {
    color: #fff !important;
    background: #333;
}

.nav .headerImg {
    display: block;
    width: 100%;
}

.navBtn {
    position: absolute;
    top: 0;
    right: 0;
    width: 100px;
    height: 50px;
    z-index: 1000000;
    border: 1px solid transparent;
    color: #fff;
}
.router-active {
    background: #666;
    color: #fff;
}

/* 可以设置不同的进入和离开动画 */
/* 设置持续时间和动画函数 */
.slide-fade-enter-active {
    transition: all 0.3s ease;
}
.slide-fade-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
}

.search {
    width: 315px !important;
    position: fixed;
    left: 0px;
    top: 15px;
    right: 0;
    margin: auto;
    box-shadow: 0 0 15px #ccc;
}
.search:hover {
    border: none;
}
.shiplist {
    width: 315px;
    left: 30px;
    top: 65px;
    position: fixed;
    border: 0px solid #ccc;
    background: rgba(255, 255, 255, 0.8);
    z-index: 1000;
    font-size: 12px;
    overflow-y: auto;
}
.shipItem {
    border-bottom: 1px solid #bbb;
    cursor: pointer;
}
.shipItem:hover {
    background: #efefef;
}
.footer {
    width: 375px;
    height: 70px;
    position: fixed;
    bottom: 0;
    left: 0;
    background: #f1f1f1;
    z-index: 11111;
    display: flex;
    flex-basis: 1;
    justify-content: space-around;
}
.footer > .navItem {
    text-align: center;
   // border: 1px solid #fff;
    position: relative;
    padding: 0;
    border: 0px solid red;
}
.footer > .navItem > img {
    width: 25px;
    height: 25px;
    display: block;
    margin: 12px auto;
    position:relative;
    top:2px
}
.footer > .navItem > p {
    text-align: center;
    font-size: 12px;
    border: 0px solid red;
    color: #999;
    margin-top: 9px;
    line-height: 12px;
}
.subNav {
    width: 120px;
    height: 105px;
    text-align: center;
    line-height: 33px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 72px;
    margin: auto;
}
.subNav > div {
    border-radius: 5px;
    font-size: 12px;
    background: #f1f1f1;
    height: 33px;
    margin-top: 2px;
}
.subNav > div.current {
    color: #ec8e37;
}

.ant-input-affix-wrapper .ant-input:focus,
.ant-input-affix-wrapper .ant-input {
    border: none;

    box-shadow: none;
    outline: 0;
}
</style>
