<template>
    <div class="wrapper" style="scroll-y:auto;border:3px solid green;" v-if="visible">
        <a-modal v-model="visible" title="中国主要港口" @ok="handleOk" width="100%" :dialog-style="{top:'55px',right:'5px',left:'5px',position:'fixed',padding:0}" :mask="false" :footer="false" :body-style="{padding:0,paddingLeft:'10px',paddingBottom:'15px',paddingTop:'12px',overflowY:'auto',height:'530px'}" @cancel="handleCancelResult">
            <a-select :default-value="currentportId" style="width: 120px" @change="handleChange">
                <a-select-option :value="item.id" v-for="(item) in ports" :key="item.port">
                    {{item.port}}
                </a-select-option>

            </a-select>
            <div class="box1">
                <div style="padding-top:20px;font-weight:bold;font-size:15px">船舶在港时间统计</div>
              
                <div class="chart1 chart" id="chart1" ></div>
               

            </div>
            <div class="box1">
                <div class="chart chart2" id="chart2"></div>
            </div>
            <div class="box1">
                <div style="padding-top:20px;font-weight:bold;font-size:15px">船舶分类</div>
                <div class="chart chart3" id="chart3"></div>
            </div>

        </a-modal>
    </div>
</template>

<script>
import { ports } from "@/utils/port.js";
import { portInfo } from "@/request/api.js";
import * as echarts from "echarts";

export default {
    props: {},
    data() {
        return {
            ports,
            visible: true,
            currentportId: 55,
            portInfo: {},
            data1: [],
            chart1: null,
            chart2: null,
            chart3: null,
        };
    },
    created() {
        //this.getShipNum();
    },
    mounted() {
        setTimeout(() => {
            this.chart1 = echarts.init(document.getElementById("chart1"));
            this.chart2 = echarts.init(document.getElementById("chart2"));
            this.chart3 = echarts.init(document.getElementById("chart3"));
            this.getPortInfo();
        }, 500);
    },
    watch: {},
    computed: {},
    methods: {
        handleOk() {},
        handleCancelResult(){
            this.$emit("close")
        },
        handleChange(val1, val2) {
            console.log("val111....",val1);
            this.currentportId = val1;
            this.getPortInfo();
        },
        getPortInfo() {
            portInfo({ portId: this.currentportId }).then((res) => {
                console.log(res);
                this.portInfo = JSON.parse(res.data);
                console.log(this.portInfo);
                let hour4 = this.getShipNum(
                    this.portInfo.vidsLt4hByShipTypeMap
                );
                let hour12 = this.getShipNum(
                    this.portInfo.vids412hByShipTypeMap
                );
                let hour24 = this.getShipNum(
                    this.portInfo.vids1224hByShipTypeMap
                );
                let hour25 = this.getShipNum(
                    this.portInfo.vidsGt24hByShipTypeMap
                );
                console.log(hour4, hour12, hour24, hour25);

                let hour4obj = { value: hour4, name: "0-4小时" };
                let hour12obj = { value: hour12, name: "4-12小时" };
                let hour24obj = { value: hour24, name: "12-24小时" };
                let hour25obj = { value: hour25, name: "24小时以上" };
                this.data1 = [hour4obj, hour12obj, hour24obj, hour25obj];
                console.log(this.time1);

                this.createChart1();
                this.createChart2();
                this.createChart3();
            });
        },
        getShipNum(obj) {
            
            let num = 0;
            Object.keys(obj).map((item) => {
                //console.log(key,item);
                num += Number(obj[item]);
            });
            return num;
        },
        createChart1() {
            let option = {
                tooltip: {
                    trigger: "item",
                },
                legend: {
                    top: "5%",
                    right: "right",
                    orient: "vertical",
                    formatter: function (name) {
                        let data = option.series[0].data;
                        let tarValue;
                        for (let i = 0; i < data.length; i++) {
                            if (data[i].name === name) {
                                tarValue = data[i].value;
                            }
                        }
                        return name + ": " + tarValue + "艘";
                    },
                },
                series: [
                    {
                        name: "时间统计",
                        type: "pie",
                        radius: ["40%", "70%"],
                        avoidLabelOverlap: false,
                        label: {
                            show: false,
                            position: "center",
                        },
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: "12",
                                fontWeight: "bold",
                            },
                        },
                        labelLine: {
                            show: false,
                        },
                        data: this.data1,
                        center: ["30%", "45%"],
                    },
                ],
            };
            this.chart1.setOption(option);
        },
        createChart2() {
            let row1 = this.getDataItem("货船", "cargo");
            let row2 = this.getDataItem("公务船", "official");
            let row3 = this.getDataItem("工程船", "offshore");
            let row4 = this.getDataItem("客船", "passenger");
            let row5 = this.getDataItem("油船", "tanker");
            let row6 = this.getDataItem("拖船", "tug");
            let row7 = this.getDataItem("渔船", "fishing");
            let row8 = this.getDataItem("其他", "other");
            let option = {
                legend: {
                    top: 20,
                },
                tooltip: {},
                dataset: {
                    source: [
                        [
                            "product",
                            "0-4小时",
                            "4-12小时",
                            "12-24小时",
                            "大于24小时",
                        ],
                        row1,
                        row2,
                        row3,
                        row4,
                        row5,
                        row6,
                        row7,
                        row8,
                    ],
                },
                xAxis: { type: "category" },
                yAxis: {},
                grid: {
                    left: 0,
                    right: 10,
                    bottom: 30,
                },
                // Declare several bar series, each will be mapped
                // to a column of dataset.source by default.
                series: [
                    { type: "bar" },
                    { type: "bar" },
                    { type: "bar" },
                    { type: "bar" },
                ],
            };
            console.log("option2>>");
            console.log(option);
            this.chart2.setOption(option);
        },
        createChart3() {
            let data = [];
            let obj = this.portInfo.totalByShipTypeCountMap;
            let name 
            Object.keys(obj).map(item=>{
                if(item == "cargo"){
                    name = "货船"
                }else if(item =="official"){
                    name= "公务船"
                }else if(item == "offshore"){
                    name = "工程船"
                }else if(item == "passenger"){
                    name ="客船"
                }else if(item == "tanker"){
                    name = "油船"
                }else if(item =="tug" ){
                    name ="拖船"
                }else if(item == "fishing"){
                    name ="渔船"
                }else if(item=="other") {
                    name ="其它"
                }
                data.push({name:name,value:obj[item]})
            })
            let option = {
                tooltip: {
                    trigger: "item",
                },
                legend: {
                    top: "5%",
                    right: 10,
                    orient: "vertical",
                },
                series: [
                    {
                        name: "船舶类型",
                        type: "pie",
                        radius: ["40%", "70%"],
                        avoidLabelOverlap: false,
                        label: {
                            show: false,
                            position: "center",
                        },
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: "11",
                                fontWeight: "bold",
                            },
                        },
                        labelLine: {
                            show: false,
                        },
                        center: ["30%", "45%"],
                        data: data
                    },
                ],
            };
            this.chart3.setOption(option)
        },
        getDataItem(name, flag) {
            let portInfo = this.portInfo;
            let cargo1Time = Number(portInfo.vidsLt4hByShipTypeMap[flag]) || 0;
            let cargo2Time = Number(portInfo.vids412hByShipTypeMap[flag]) || 0;
            let cargo3Time = Number(portInfo.vids1224hByShipTypeMap[flag]) || 0;
            let cargo4Time = Number(portInfo.vidsGt24hByShipTypeMap[flag]) || 0;
            let dataItem = [
                name,
                cargo1Time,
                cargo2Time,
                cargo3Time,
                cargo4Time,
            ];
            return dataItem;
        },
    },
    components: {},
};
</script>
<style scoped>
.chart {
    width: 350px;
    height: 240px;
    border: 5px solid red;
}
.chart1 {
    width: 350px;
    height: 190px;
    border: 0px solid red;
}
.chart2 {
    width: 350px;
    height: 240px;
    border: 0px solid red;
}
.chart3 {
    width: 350px;
    height: 240px;
    border: 0px solid red;
}

.box1 {
    position: relative;
}
.box1 .labelZone {
}
</style>