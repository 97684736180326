<template>
    <div class="wrapper">
        <a-modal title="我的关注" centered width="500px" :footer="false" @ok="this.$emit('close')" v-model="isAttation" :mask="false" :dialogStyle="{position:'fixed',right:'10px',top:'100px',borderRadius:'8px'}" :bodyStyle="{paddingTop:0,maxHeight:'300px',border:'0px solid red',overflow:'auto'}" @cancel="cancel">
            <div class="attationWrapper">
                <div class="loading" v-if="isLoading" style="text-align:center;line-height:180px">
                    <a-icon type="loading-3-quarters" />
                </div>
                <template v-else-if="attationList.length>0">
                    <div class="line" v-for="item in attationList " :key="item.posTime" style=" ">

                        <div style="padding-left:5px">
                            <b>MMSI:</b>{{item.mmsi}}
                            <div style="text-align:right;float:right;margin-left:3px" @click="del(item.shipId)">
                                <a-button size="small">删除</a-button>
                            </div>
                            <div style="text-align:right;float:right" @click="shipDetail(item.shipId,item.name)">
                                <a-button size="small">查看</a-button>
                            </div>

                        </div>
                        <div style="padding-left:5px">
                            <b> 船名:</b>{{item.name}}
                        </div>
                    </div>
                </template>
                <template v-else-if="attationList.length==0">
                    <div style="border-bottom:1px solid #f1f1f1; font-size:20px;width:100% ;text-align:center;line-height:200px">
                        暂无数据
                    </div>
                </template>

            </div>

        </a-modal>
    </div>
</template>

<script>
import { attentionList, delAttention, shipAsc } from "@/request/api.js";

export default {
    props: {},
    data() {
        return {
            isAttation: true,
            attationList: [],
            isLoading: false,
        };
    },
    created() {
        this.isAttation = true;
        this.getList();
    },
    mounted() {},
    watch: {},
    computed: {},
    methods: {
        cancel() {
            this.$emit("close");
        },
        getList() {
            attentionList().then((res) => {
                console.log(res);
                this.attationList = res.data;
            });
        },
        del(shipId) {
            console.log(shipId);
            let that = this;
            this.$confirm({
                title: "提示",
                content: (h) => <div style="color:red;">确定删除?</div>,
                onOk() {
                    console.log("OK");
                    delAttention({ shipId }).then((res) => {
                        that.attationList = that.attationList.filter(
                            (item) => item.shipId != shipId
                        );
                    });
                },
                onCancel() {
                    console.log("Cancel");
                },
                okText:"确定",
                cancelText:"取消",
                class: "test",
            });
        },
        shipDetail(shipId, shipName) {
            console.log(shipId,shipName)
            shipAsc({ shipId: shipId }).then((res) => {
                let data = JSON.parse(res.data);
                console.log("data>>", data);
                this.$parent.setCenter(data[0], shipName);
            });
        },
    },
    components: {},
};
</script>
<style scoped>
.attationWrapper {
    padding: 0 5px;
    max-height: 400px;
    overflow: hidden;
    overflow-y: auto;
    min-height: 200px;
    font-size: 13px;
}
.line {
    height: 60px;
    margin-top: 5px;
    align-items: center;
    border-bottom: 1px solid #f1f1f1;
    cursor: pointer;
}
.line > div:nth-child(1) {
    line-height: 35px;
}
.line > div {
    line-height: 30px;
    height: 30px;

    border: 0px solid red;
    margin-top: 0;
}
</style>