<template>
    <div class="wrapper">

        <a-modal title="登录" :visible="isLogin" :confirm-loading="confirmLoading" @ok="handleOk" @cancel="handleCancelLogin" :footer="false" centered width="450px" :bodyStyle="{paddingBottom:'10px',paddingTop:'5px'}">
            <a-form :form="form" @submit="handleSubmit">
                <a-form-item :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol" label="">
                    <a-input size="large" v-decorator="[
          'phone',
          { rules: [{ required: true, message: '请输入手机号码' },{pattern:/^1\d{10}$/,message:'请输入正确的手机号'}] },
        ]" placeholder="手机号码" />
                </a-form-item>
                <a-form-item :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol" label="" style="position:relative">
                    <a-input size="large" v-decorator="[
          'code',
          { rules: [{ required: checkNick, message: '请输入手机验证码' }] },
        ]" placeholder="短信验证码">
                        <a-button type="primary" slot="suffix" size="small" @click="sendCode" v-if="leftSecond==60">
                            发送
                        </a-button>
                        <a-button type="default" slot="suffix" size="small" v-else>
                            {{leftSecond}} 秒
                        </a-button>
                    </a-input>
                </a-form-item>

                <a-form-item :label-col="{span:0}" :wrapper-col="{span:22,offset:1}">
                    <a-button type="primary" style="width:100%;height:40px" html-type="submit" size="large">
                        登录
                    </a-button>
                </a-form-item>
             

            </a-form>
        </a-modal>
        <!--
            <a-modal title="注册" :visible="isRegister" :confirm-loading="confirmLoading" @ok="handleOk" @cancel="handleCancelLogin" :footer="false" centered width="450px">

            <a-form :form="form">
                <a-form-item label=""  :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol">
                    <a-radio-group v-decorator="['radio-group']">
                        <a-radio value="a">
                            我是货主
                        </a-radio>
                        <a-radio value="b">
                            我是船主
                        </a-radio>
                        
                    </a-radio-group>
                </a-form-item>
                <a-form-item :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol" label="">
                    <a-input v-decorator="[
          'username',
          { rules: [{ required: true, message: 'Please input your name' }] },
        ]" placeholder="Please input your name" />
                </a-form-item>
                <a-form-item :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol" label="">
                    <a-input v-decorator="[
          'nickname',
          { rules: [{ required: checkNick, message: 'Please input your nickname' }] },
        ]" placeholder="Please input your nickname" />
                </a-form-item>
                <a-form-item :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol" label="">
                    <a-input v-decorator="[
          'nickname',
          { rules: [{ required: checkNick, message: 'Please input your nickname' }] },
        ]" placeholder="Please input your nickname" />
                </a-form-item>
                <a-form-item :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol" label="">
                    <a-input v-decorator="[
          'nickname',
          { rules: [{ required: checkNick, message: 'Please input your nickname' }] },
        ]" placeholder="Please input your nickname" />
                </a-form-item>

                <a-form-item :label-col="{span:0}" :wrapper-col="{span:22,offset:1}">
                    <a-button type="primary" @click="check" style="width:100%">
                        注册
                    </a-button>
                </a-form-item>

            </a-form>
        </a-modal>


        -->

    </div>
</template>

<script>
import { sendCode, login } from "@/request/api.js";
const formItemLayout = {
    labelCol: { span: 0 },
    wrapperCol: { span: 22, offset: 1 },
};
const formTailLayout = {
    labelCol: { span: 0 },
    wrapperCol: { span: 22, offset: 1 },
};
export default {
    props: {},
    data() {
        return {
            isLogin: true,
            isRegister: false,
            confirmLoading: false,
            checkNick: false,
            formItemLayout,
            formTailLayout,
            form: this.$form.createForm(this, { name: "dynamic_rule" }),
            timer: null,
            leftSecond: 60,
        };
    },
    created() {},
    mounted() {},
    watch: {},
    computed: {},
    methods: {
        handleCancelLogin() {
            this.$emit("close");
        },
        handleOk() {},
        handleSubmit(e) {
            //登录
            let data = { phone: 11, code: 1233 };
            e.preventDefault();
            this.form.validateFieldsAndScroll((err, values) => {
                if (!err) {
                    console.log("Received values of form: ", values);
                    login({
                        phone: values["phone"],
                        code: values["code"],
                    }).then((res) => {
                        console.log(res);
                        localStorage.setItem("token", res.data);
                        localStorage.setItem("tel", values["phone"]);
                        this.$parent.setTel(values["phone"]);
                        this.$emit("close");
                    });
                } 
            });
        },
        sendCode() {
            let phone = this.form.getFieldValue("phone");
            let reg = /^1\d{10}$/;
            console.log("phone>>", phone);
            console.log(reg.test(phone))
            if (!reg.test(phone)) {
                console.log("111")
            } else {
                  this.leftSecond--;
                console.log("222")
                sendCode({ phone: phone }).then((res) => {
                    this.timer = setInterval(() => {
                        console.log(this.leftSecond);
                        if (this.leftSecond > 0) {
                            this.leftSecond--;
                        } else {
                            clearInterval(this.timer);
                            this.leftSecond = 60;
                            this.timer = null;
                        }
                    }, 1000);
                });
            }
        },
    },
    components: {},
};
</script>
<style scoped>
.wrapper {
    background: #fff;
}
.ant-row {
    margin: 15px;
}
.ant-form-item-with-help {
    margin-bottom: 0;
}
</style>